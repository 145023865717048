import styled from 'styled-components';

import { Link } from '@bscom/components/Links';
import { Spacing } from '@bscom/components/Spacing';
import { desktops } from '@bscom/styling/breakpoints';
import { Markdown } from '@bscom/components/Markdown';
import { ContentfulMediaImage } from '@bscom/components/Image';
import { MAX_PAGE_WIDTH } from '@bscom/constants/widths';
import { Background } from '@bscom/components/Background';
import { WHITE, GREY_DARKEST } from '@bscom/constants/colors';

const PagesWrapper = styled.div`
  margin: 0 auto;
  max-width: 600px;

  @media (${desktops}) {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
  }
`;

const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 0 20px;
  background-color: ${WHITE};

  a:hover {
    text-decoration: none;
  }

  img {
    width: 100%;
    max-width: 100%;
  }

  @media (${desktops}) {
    width: calc(100% / 3 - 20px);
    margin: 0 10px;
  }
`;

const Headline = styled.h2`
  font-size: 21px;
  line-height: 26px;
  padding: 46px 40px 20px;

  @media (${desktops}) {
    font-size: 24px;
    line-height: 34px;
  }
`;

const PageDescription = styled.p`
  max-width: 500px;
  margin: 0;
  padding: 0 40px 104px;
  color: ${GREY_DARKEST};

  @media (${desktops}) {
    max-width: 360px;
  }
`;

const DescriptionLink = styled(Link)`
  text-decoration: none;
`;

const PageLink = styled.a`
  display: block;
  position: absolute;
  bottom: 0;
  padding: 0 40px 46px;

  &::after {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='7' height='11' viewBox='0 0 10 15' fill='none'><path d='M1.39726 0L10 8.33333L1.39726 16.6667L0 15.3132L7.20548 8.33333L0 1.35351L1.39726 0Z' fill='%231A66FF'/></svg>");
    margin-left: 10px;
  }
`;

const descriptionRenderers = {
  p: ({ children }) => <PageDescription>{children}</PageDescription>,
  a: ({ href, children }) => (
    <DescriptionLink href={href}>{children}</DescriptionLink>
  ),
};

const RelatedPage = ({ link, linkText, name, img, description }) => (
  <PageWrapper>
    <Link href={link}>
      <ContentfulMediaImage
        image={img}
        ratio="16/10"
        sizes={`(${desktops}) 25vw, 75vw`}
        format="webp"
        quality="80"
        style={{ objectFit: 'cover' }}
      />
    </Link>
    <Headline>{name}</Headline>
    <Markdown customRenderers={descriptionRenderers}>{description}</Markdown>
    <PageLink href={link}>{linkText}</PageLink>
  </PageWrapper>
);

const RelatedPages = ({
  spacingCollection,
  relatedPagesCollection,
  background,
}) => {
  return (
    <Background {...background}>
      <Spacing
        spaces={spacingCollection.items}
        withXPadding
        contentWidth={MAX_PAGE_WIDTH}
      >
        <PagesWrapper>
          {relatedPagesCollection.items.map((relatedPage, index) => (
            <RelatedPage key={index} {...relatedPage} />
          ))}
        </PagesWrapper>
      </Spacing>
    </Background>
  );
};

export default RelatedPages;
